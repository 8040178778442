import React from 'react';
import { useTranslation } from 'react-i18next';
import { IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import { Overlay, OverlayBackdropColor } from '../Overlay/Overlay';
import { useDeviceInfo } from '../../hooks/useDeviceInfo';
import { useStyles } from '../../hooks/useStyles';
// TODO: Remind that illustrators would draw an icon so that there would be no conflicts with copyright holders
import iconShouldRotate from './assets/images/shouldRotate.png';
import { getStyles, IForcePortraitModeStyleProps, IForcePortraitModeStyles } from './styles/ForcePortraitMode';

interface IForcePortraitModeProps extends Partial<IForcePortraitModeStyleProps> {
  styles?: IStyleFunctionOrObject<IForcePortraitModeStyleProps, IForcePortraitModeStyles>;
}

const ForcePortraitModeBase: React.FC<IForcePortraitModeProps> = ({ styles }) => {
  const { t } = useTranslation();
  const { isLandscape } = useDeviceInfo();

  const message = t('ForcePortraitMode.Message');
  const classNames = useStyles<IForcePortraitModeStyles, IForcePortraitModeStyleProps>(styles);

  return (
    <Overlay
      className={classNames.overlay}
      isVisible={isLandscape}
      overlayBackdropColor={OverlayBackdropColor.primarySolid}
    >
      <img src={iconShouldRotate} alt={message} />
      <div className={classNames.message}>
        { message }
      </div>
    </Overlay>
  );
};

export const ForcePortraitMode = styled<IForcePortraitModeProps, IForcePortraitModeStyleProps, IForcePortraitModeStyles>(
  ForcePortraitModeBase,
  getStyles,
);
