import { IStyle } from '@fluentui/merge-styles';
import { IDeviceInfoContext } from '../../../types';
import { Color } from '../../../styles/constants';
import { hexToRGBA } from '../../../styles/helpers';

export enum OverlayBackdropColor {
  primary,
  primarySolid,
  secondary,
  transparent,
  tertiary,
}

export interface IOverlayStyleProps extends IDeviceInfoContext {
  className?: string;
  classNameContent?: string;
  classNameOverlayBackDrop?: string;
  overlayBackdropColor?: OverlayBackdropColor;
  isScatter?: boolean;
  isLobby?: boolean;
}

export interface IOverlayStyles {
  root: IStyle;
  overlayBackdrop: IStyle;
  overlayContent: IStyle;
}

export const getStyles = ({
  className,
  classNameContent,
  overlayBackdropColor = OverlayBackdropColor.primary,
  calculateRemValue,
  classNameOverlayBackDrop,
  isScatter,
  isLobby,
}: IOverlayStyleProps): IOverlayStyles => ({
  root: [
    {
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
      top: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2,
    },
    className,
  ],
  overlayBackdrop: [
    {
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: hexToRGBA(Color.overlay.primaryBackgroundColor, 0.5),
    },
    overlayBackdropColor === OverlayBackdropColor.primarySolid && {
      background: Color.overlay.primaryBackgroundColor,
    },
    overlayBackdropColor === OverlayBackdropColor.secondary && {
      background: hexToRGBA(Color.overlay.secondaryBackgroundColor, 0.5),
    },
    overlayBackdropColor === OverlayBackdropColor.tertiary && {
      background: hexToRGBA(Color.overlay.tertiaryBackgroundColor, 0.6),
    },
    overlayBackdropColor === OverlayBackdropColor.transparent && {
      background: 'none',
    },
    classNameOverlayBackDrop,
  ],
  overlayContent: [
    {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      textAlign: 'center',
      color: Color.white,
      zIndex: 1,
      maxWidth: '100%',
    },
    classNameContent,
  ],
});
