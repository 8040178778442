import { IStyle } from '@fluentui/merge-styles';
import { Color, FontSize } from '../../../styles/constants';
import { IDeviceInfoContext } from '../../../types';
import iconDivider from '../assets/divider.png';

export interface IAccordionStyleProps extends IDeviceInfoContext {
  className?: string;
  isExpanded: boolean;
}

export interface IAccordionStyles {
  accordion: IStyle;
  header: IStyle;
  icon: IStyle;
  children: IStyle;
}

export const getStyles = ({ className, calculateRemValue, isExpanded }: IAccordionStyleProps): IAccordionStyles => ({
  accordion: [
    {
      position: 'relative',
      marginTop: '0px',
      cursor: 'pointer',
      padding: `${calculateRemValue(5)} ${calculateRemValue(2)} ${calculateRemValue(2)} ${calculateRemValue(5)}`,
      selectors: {
        '&:after': {
          content: '""',
          position: 'absolute',
          backgroundImage: `url(${iconDivider})`,
          bottom: 0,
          left: calculateRemValue(4),
          right: calculateRemValue(13),
          height: '2rem',
        },
        '&:first-child:before': {
          content: '""',
          position: 'absolute',
          backgroundImage: `url(${iconDivider})`,
          top: 0,
          left: calculateRemValue(4),
          right: calculateRemValue(13),
          height: '2rem',
        },
      },
    },
    className,
  ],
  header: [
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
    isExpanded && {
      color: Color.menuHeader,
    },
  ],
  icon: [
    {
      stroke: 'currentColor',
      transform: 'rotate(-90deg)',
      transition: 'transform 0.8s',
      width: calculateRemValue(3),
      marginRight: '11rem',
    },
    isExpanded && {
      transform: 'rotate(0)',
    },
  ],
  children: {
    borderTop: `2px solid ${Color.border}`,
    transformOrigin: 'top center',
    maxWidth: '52rem',
    marginTop: '4rem',
  },
});
