import { INormalizedEntities } from 'arcadia-common-fe';
import { ICameras } from '../cameras/types';
import { BrandId } from '../../types/brand';

// TODO: Review all types in this file and replace any

export enum EntityType {
  groupName = 'groupName',
  groupId = 'groupId',
  denominator = 'denominator',
  operatorName = 'operatorName',
  operatorId = 'operatorId',
  machineName = 'machineName',
  machineSerial = 'machineSerial',
  location = 'location',
  siteName = 'siteName',
  camerasSiteName = 'camerasSiteName',
  siteId = 'siteId',
  blockingReason = 'blockingReason',
  chipType = 'chipType',
  monitoringMetric = 'monitoringMetric',
  monitoringDimension = 'monitoringDimension',
  rebateCurrency = 'rebateCurrency',
  prizeGroup = 'prizeGroup',
  powerLines = 'powerLines',
  freeCameraId = 'freeCameraId',
  brandSecretsId = 'brandSecretsId',
  brandName = 'brandName',
  brandId = 'brandId',
  apiConnectorId = 'apiConnectorId',
  gameVariation = 'gameVariation',
  groupType = 'groupType',
  sessionEndReason = 'sessionEndReason'
}

export type OptionId = number | string;

export interface IOption {
  value: string;
  label: string;
  apiConnectorId?: string;
}

export type IOptions = IOption[];

export type IOptionsEntities = INormalizedEntities<IOption, OptionId>;

export interface IEntity {
  ids: OptionId[];
  entities: IOptionsEntities;
  isLoading: boolean;
}

export type ISelectEntityByTypeReducer = { [key in EntityType]?: IEntity };

export interface ISelectEntityByTypeSlice {
  selectEntityByTypeReducer: ISelectEntityByTypeReducer;
}

export type IRequestQueryParams = Record<string, string | number | undefined>;

export interface IGetEntityRequestBody {
  entityType: EntityType;
  requestQueryParams?: IRequestQueryParams;
  isAutoRefresh?: boolean;
  disableDuplicateRequest?: boolean;
  refreshInterval?: number;
}

export interface IGetLocationsResponseBody {
  locations: string[];
}

export interface ISite {
  id: number;
  name: string;
}

export interface IGetSitesResponseBody {
  sites: ISite[];
}

export interface IGetCamerasResponseBody {
  cameras: ICameras;
}

export interface IGetBlockingReasonsResponseBody {
  reasons: string[];
}

export interface IGetDenominatorValuesResponseBody {
  denominators: string[];
}

export interface IChip {
  id: number;
  name: string;
}

export interface IGetChipTypesResponseBody {
  chipTypes: IChip[];
}

export interface IGetMonitoringMetricsResponseBody {
  metric: string[];
}

export interface IDimension {
  id: number;
  name: string;
}

export interface IGetMonitoringDimensionsResponseBody {
  dimension: IDimension[];
}

export type IGetRebateCurrenciesResponseBody = string[];

export interface IGetPrizeGroupsResponseBody {
  groups: string[];
}

export interface IPowerLine {
  name: string;
}

type IPowerLines = IPowerLine[];

export interface IGetPowerLinesResponseBody {
  powerLines: IPowerLines;
}

export interface IBrandData {
  id: BrandId;
  name: string;
}

type IBrandsData = IBrandData[];

export interface IGetBrandsResponseBody {
  brands: IBrandsData;
}

export interface IGroup {
  id: number;
  name: string;
  denominator: number;
}

export interface IGetGroupsResponseBody {
  groups: IGroup[];
}

export interface IMachine {
  id: number;
  name: string;
  machineSerial: string;
}

export interface IGetMachinesResponseBody {
  machines: IMachine[];
}

export interface IOperator {
  id: number;
  name: string;
  apiConnectorId: string;
}

export interface IGetOperatorsResponseBody {
  operators: IOperator[];
}

export interface IApiConnector {
  apiConnectorId: string;
}

export interface IGetAPIConnectorResponseBody {
  apiConnectors: IApiConnector[];
}

export interface IGetGameVariation {
  gameVariation: string;
}

export interface IGetGameVariationsResponseBody {
  gameVariations: IGetGameVariation[];
}

export interface IGetGroupType {
  groupType: string;
}

export interface IGetGroupTypesResponseBody {
  groupTypes: IGetGroupType[];
}

export interface ISessionEndReason {
  sessionEndReason: string;
  displaySessionEndReason: string;
}

export interface IGetSessionEndReasonResponseBody {
  sessionEndReasons: ISessionEndReason[];
}
