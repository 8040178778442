import { IStyle } from '@fluentui/merge-styles';
import { IDeviceInfoContext } from '../../../../../types/general';
import { Color, FontFamily, FontSize } from '../../../../../styles/constants';

export interface IMainMenuPanelStyleProps extends IDeviceInfoContext {
}

export interface IMainMenuPanelStyles {
  mainMenu: IStyle;
  item: IStyle;
  icon: IStyle;
  panel: IStyle;
  iconBack: IStyle;
  backBtn: IStyle;
}

export const getStyles = ({ calculateRemValue }: IMainMenuPanelStyleProps): IMainMenuPanelStyles => ({
  mainMenu: {
    textTransform: 'uppercase',
    marginTop: calculateRemValue(6.5),
  },
  iconBack: {
    cursor: 'pointer',
    position: 'absolute',
    right: calculateRemValue(3),
    width: calculateRemValue(3.93),
    transform: 'rotate(180deg)',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginTop: calculateRemValue(7.36),
    cursor: 'pointer',
    selectors: {
      ':first-child': {
        marginTop: 0,
      },
    },
  },
  icon: {
    width: calculateRemValue(7.5),
    marginRight: calculateRemValue(3.57),
    fill: Color.panel.headerIconFillColor,
  },
  panel: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 1,
    width: calculateRemValue(92.86),
    height: '100%',
    padding: `${calculateRemValue(4.6)} ${calculateRemValue(5.56)}`,
    color: Color.primaryTextColor,
    backgroundColor: Color.panel.backgroundColor,
    fontFamily: FontFamily.secondary,
    fontSize: calculateRemValue(FontSize.Size4),
    letterSpacing: calculateRemValue(-0.15),
    overflow: 'auto',
  },
  backBtn: {
    position: 'absolute',
    top: '8rem',
    right: '3rem',
    width: '9rem',
    height: '9rem',
    borderRadius: '50%',
    backgroundColor: 'transparent',
    zIndex: 3,
  },
});
